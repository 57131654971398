import React, { useState, useEffect } from 'react';
import { Upload, Globe, ArrowRight, Play } from 'lucide-react';

const AutoDemo = ({language}) => {
    const [step, setStep] = useState(0);
    const [file, setFile] = useState(null);
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [isTraining, setIsTraining] = useState(false);
    const [activeField, setActiveField] = useState(null);

    const languages = ['English', 'Awajun', 'Finnish', 'Spanish', 'French', 'German', 'Italian', 'Portuguese', 'Russian', 'Chinese', 'Japanese', 'Korean', 'Arabic', 'Hindi', 'Bengali', 'Dutch', 'Turkish', 'Polish', 'Swedish', 'Vietnamese', 'Thai', 'Greek', 'Nahuatl'];

    const steps = [
        { action: () => { setFile('training_data.json'); setActiveField('file'); }, delay: 1000 },
        { action: () => { setSourceLanguage('English'); setActiveField('sourceLanguage'); }, delay: 1000 },
        { action: () => { setTargetLanguage('Nahuatl'); setActiveField('targetLanguage'); }, delay: 1000 },
        { action: () => { setIsTraining(true); setActiveField(null); }, delay: 2000 },
        { action: () => setIsTraining(false), delay: 2000 },
        {
            action: () => {
                setFile(null);
                setSourceLanguage('');
                setTargetLanguage('');
                setActiveField(null);
            }, delay: 3000
        }
    ];

    useEffect(() => {
        if (step < steps.length) {
            const timer = setTimeout(() => {
                steps[step].action();
                setStep(step + 1);
            }, steps[step].delay);
            return () => clearTimeout(timer);
        } else {
            setStep(0);
        }
    }, [step]);

    const getFieldStyle = (fieldName) => {
        return `w-full p-2 border rounded ${activeField === fieldName ? 'border-blue-500 shadow-lg' : ''} ${eval(fieldName) ? 'font-semibold' : ''}`;
    };

    return (
        <div translate="no" className="bg-white text-black rounded-lg shadow-lg px-6 py-12 w-full">
            <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="col-span-2 py-4">
                    <div className={`border-2 border-dashed rounded-lg p-4 text-center ${activeField === 'file' ? 'border-blue-500 shadow-lg' : 'border-gray-300'}`}>
                        {file ? (
                            <p className="text-green-600 font-bold"><Upload className="inline mr-2" size={16} />
                                {language === "gaia-en"
                                    ? `${file} uploaded`
                                    : `${file} subido`
                                }
                            </p>
                        ) : (
                            <p className="text-gray-700">
                                    {language === "gaia-en"
                                        ? "Upload a JSON file (up to 10MB)"
                                        : "Sube un archivo JSON (hasta 10MB)"
                                    }
                            </p>
                        )}
                    </div>
                </div>
                <div>
                    <select value={sourceLanguage} className={getFieldStyle('sourceLanguage')} disabled>
                        <option value="">
                            {language === "gaia-en"
                                ? "Source Language"
                                : "Idioma de Origen"
                            }
                        </option>
                        {languages.map(lang => <option key={lang} value={lang}>{lang}</option>)}
                    </select>
                </div>
                <div>
                    <select value={targetLanguage} className={getFieldStyle('targetLanguage')} disabled>
                        <option value="">
                            {language === "gaia-en"
                                ? "Target Language"
                                : "Idioma de Destino"
                            }
                        </option>
                        {languages.map(lang => <option key={lang} value={lang}>{lang}</option>)}
                    </select>
                </div>
            </div>
            <button className="bg-blue-600 mt-4 text-white font-semibold px-4 py-2 rounded hover:bg-blue-700 transition duration-300 w-full" disabled>
                {isTraining ? (
                    <><Play className="inline mr-2 animate-pulse" size={16} />
                        {language === "gaia-en"
                            ? "Building..."
                            : "Construyendo..."
                        }
                    </>
                ) : (
                    <>
                            {language === "gaia-en"
                                ? "Build agent"
                                : "Construir agente"
                            }
                    <ArrowRight className="inline ml-2" size={16} /></>
                )}
            </button>
            <div className="mt-4 text-base text-black">
                <Globe className="inline mr-2" size={16} />
                {language === "gaia-en"
                    ? "Translation agents built using Gaia achieve state-of-the-art performance with a relative small amount of training data"
                    : "Los agentes de traducción construidos con Gaia logran un rendimiento de vanguardia con una cantidad relativamente pequeña de datos de entrenamiento"
                }
            </div>
        </div>
    );
};

export default AutoDemo;