import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Globe, Copy, Eraser, Check } from 'lucide-react';
import { FaLanguage, FaExchangeAlt } from "react-icons/fa";
import { doc, getDoc } from 'firebase/firestore';
import { db } from "../firebase";

const PublicModel = ({ language }) => {
    const { modelId } = useParams();
    const navigate = useNavigate();
    const [model, setModel] = useState(null);
    const [sourceText, setSourceText] = useState('');
    const [translation, setTranslation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingModel, setLoadingModel] = useState(true);
    const [srcLang, setSrcLang] = useState('');
    const [tgtLang, setTgtLang] = useState('');
    const [created_at, setCreatedAt] = useState()
    const [custom_prompt, setCustomPrompt] = useState('');
    const [copied, setCopied] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [details, setDetails] = useState(null)

    useEffect(() => {
        const fetchModelDetails = async () => {
            try {
                const sotaDoc = await getDoc(doc(db, "sota", modelId));
                if (sotaDoc.exists()) {
                    const modelData = { id: sotaDoc.id, ...sotaDoc.data() };
                    setModel(modelData);
                    setSrcLang(modelData.src_lang);
                    setTgtLang(modelData.tgt_lang);
                    setCustomPrompt(modelData?.custom_prompt)
                    setCreatedAt(modelData?.created_at)
                    document.title = `${modelData.src_lang} → ${modelData.tgt_lang} | Gaia ML`;
                } else {
                }
            } catch (error) {
            } finally {
                setLoadingModel(false);
            }
        };

        fetchModelDetails();

        return () => {
            document.title = "Gaia - No code platform to build and deploy neural machine translation models";
        };
    }, [modelId]);

    const handleTranslate = async () => {
        if (!sourceText.trim()) return;
        setLoading(true);
        setTranslation(null);
        setDetails(null)
        try {
            const response = await fetch(
                `https://agentic.gaia-ml.com/api/translate/`,
                {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json',
                        'X-API-Key': '1kfb_wSn68Gzd-V4n18t3SqaZnis3bb-TqE8YazruII'
                    },
                    body: JSON.stringify({
                        text: sourceText,
                        model_id: model.id,
                        src_lang: srcLang,
                        tgt_lang: tgtLang
                    }),
                }
            );
            console.log(response)
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || "Translation request failed");
            }

            const data = await response.json();
            if (data.success && data.data) {
                setTranslation(data.data);
                setDetails(data)
            } else {
                setTranslation({ error: 'Invalid response from server' });
            }
        } catch (error) {
            setTranslation({ error: error.message });
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
    };

    const formatDate = (timestamp) => {
        if (!timestamp || !timestamp.toDate) return 'N/A';
        const date = timestamp.toDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    };

    const copyApiExample = () => {
        const apiExample = `curl -X POST https://fst.gaia-ml.com/api/translate/ \\
    -H "Content-Type: application/json" \\
    -d '{
        "text": "Me gusta jugar con mi gato",
        "model_id": "${model?.id}",
        "src_lang": "${srcLang}",
        "tgt_lang": "${tgtLang}",
        "index_name": "${model?.index_name}",
        "file_path": "${model?.file_path}"
    }'`;
        navigator.clipboard.writeText(apiExample);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleLanguageSwap = () => {
        setSrcLang(tgtLang);
        setTgtLang(srcLang);
        setSourceText('');
        setTranslation(null);
        setWordCount(0);
        setDetails(null)
    };

    const handleTextChange = (e) => {
        const text = e.target.value;
        const words = text.trim().split(/\s+/);
        if (words.length <= 10 || text === '') {
            setSourceText(text);
            setWordCount(text === '' ? 0 : words.length);
        }
    };

    if (loadingModel) {
        return (
            <div className="min-h-screen bg-gradient-to-r from-gray-950 via-gray-950 to-black text-white flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
            </div>
        );
    }

    if (!model) {
        return (
            <div className="min-h-screen bg-gradient-to-r from-gray-950 via-gray-950 to-black text-white flex items-center justify-center">
                <div className="text-center">
                    <h2 className="text-2xl font-bold mb-4"> {language === "gaia-en" ? "Model not found" : "Modelo no hallado"}</h2>
                    <button
                        onClick={() => navigate(-1)}
                        className="text-blue-500 hover:text-blue-600 flex items-center justify-center"
                    >
                        <ArrowLeft size={20} className="mr-2" />
                        {language === "gaia-en" ? "Go back" : "Regresar"}
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div translate="no" className="min-h-screen bg-gradient-to-r from-gray-950 via-gray-950 to-black text-white">
            <div className="container mx-auto px-4 sm:px-20 py-8">
                <div className="flex justify-between items-center mb-6">
                    <button
                        onClick={() => navigate(-1)}
                        className="flex items-center text-blue-500 hover:text-blue-600"
                    >
                        <ArrowLeft size={20} className="mr-2" />
                        {language === "gaia-en" ? "Back to agents" : "Regresar a agentes"}
                    </button>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="m-2 sm:m-6">
                        <h2 className="text-xl text-blue-500 font-semibold mb-6 flex items-center">
                            <Globe className="mr-2 text-blue-500" size={24} />
                            {srcLang} → {tgtLang}
                        </h2>
                        <div className="flex flex-wrap items-center gap-8 mb-6">
                            <div className="flex-1">
                                <div className="w-full rounded-xl border border-gray-700 p-3 bg-gray-800">
                                    {srcLang}
                                </div>
                            </div>

                            <div className="flex items-center">
                                <button
                                    type="button"
                                    onClick={handleLanguageSwap}
                                    className="p-3 rounded-full hover:bg-gray-700 text-gray-300"
                                >
                                    <FaExchangeAlt />
                                </button>
                            </div>

                            <div className="flex-1">
                                <div className="w-full rounded-xl border border-gray-700 p-3 bg-gray-800">
                                    {tgtLang}
                                </div>
                            </div>
                        </div>
                        <div className="mb-6">
                            <div className="flex justify-between items-center mb-2">
                                <label className="text-base font-medium text-gray-300">
                                    {srcLang} ({wordCount}/10 words)
                                </label>
                                <div className="flex gap-2">
                                    <button
                                        onClick={() => {
                                            setSourceText('');
                                            setTranslation(null);
                                            setWordCount(0);
                                        }}
                                        className="text-gray-400 hover:text-blue-500"
                                    >
                                        <Eraser size={18} />
                                    </button>
                                </div>
                            </div>
                            <textarea
                                className="w-full p-4 border outline-none border-gray-700 rounded-lg h-40 resize-none bg-gray-800 text-white"
                                value={sourceText}
                                onChange={handleTextChange}
                                placeholder={language === "gaia-en" ? "Enter up to 10 words in" : "Ingrese hasta 10 palabras en"}
                            />
                        </div>

                        <div className="flex justify-center mb-6">
                            <button
                                className={`rounded-full bg-blue-500 py-3 px-8 text-white font-semibold shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 transition ${loading || !sourceText ? "opacity-50 cursor-not-allowed" : ""}`}
                                onClick={handleTranslate}
                                disabled={loading || !sourceText.trim()}
                            >
                                {loading ? (
                                    <div className="flex items-center">
                                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                                        {language === "gaia-en" ? "Translating..." : "Traduciendo..."}
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <FaExchangeAlt className="mr-2" />
                                            {language === "gaia-en" ? "Translate" : "Traducir"}
                                    </div>
                                )}
                            </button>
                        </div>
                        {translation && (
                            <div>
                                <p className='py-8 text-gray-500'>
                                    {language === "gaia-en" ? "Translation time:" : "Tiempo de traducción:"} {details?.response_time ? `${details?.response_time} ${language === "gaia-en" ? "seconds" : "segundos"}` : (language === "gaia-en" ? "Not available" : "No disponible")}
                                </p>
                            </div>
                        )}
                                    {translation && (
                                        <div className="mt-10 space-y-8">
                                <div className="bg-gray-800 shadow-md rounded-2xl p-6">
                                    <h3 className="text-xl font-semibold mb-5 text-blue-500 flex items-center">
                                        <FaLanguage className="mr-2" /> {language === "gaia-en" ? "Translation Options" : "Opciones de Traducción"}
                                                </h3>
                                                <ul className="space-y-4">
                                                    {translation?.translations ? (
                                                        translation?.translations?.map((t, i) => (
                                                            <li
                                                                key={i}
                                                                className="p-4 bg-gray-900 rounded-lg border border-gray-700 text-gray-300 shadow-sm flex justify-between items-center"
                                                            >
                                                                {t}
                                                            </li>
                                                        ))
                                                    ) : translation?.raw_translation ? (
                                                        (() => {
                                                            const translations = translation?.raw_translation
                                                                ?.match(/\d+\.\s*(.*?)(?=(?:\n\d+\.|$))/gs)
                                                                ?.map(t => t.replace(/^\d+\.\s*/, '').trim())
                                                                || [];
                        
                                                            return translations?.map((t, i) => (
                                                                <li
                                                                    key={i}
                                                                    className="p-4 bg-gray-900 rounded-lg border border-gray-700 text-gray-300 shadow-sm flex justify-between items-center"
                                                                >
                                                                    {t}
                                                                </li>
                                                            ));
                                                        })()
                                                    ) : (
                                                        <li className="p-4 bg-gray-50 rounded-lg border border-gray-200 text-gray-800 shadow-sm">
                                                        {language === "gaia-en" ? "No translations available" : "No hay traducciones disponibles"}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                    </div>
                    <div className="rounded-lg p-2">
                        {/*
    <h2 className="text-xl font-semibold mb-6 text-blue-500">
        {language === "gaia-en" ? "Model description" : "Descripción del modelo"}
    </h2>
    <p> {custom_prompt} </p>
    <h3 className="text-xl pt-6 font-medium text-gray-300">
        {language === "gaia-en" ? "API Usage Example" : "Ejemplo de uso de API"}
    </h3>
    {formatDate(created_at)}
    */}
                        <h2 className="text-xl font-semibold mb-6 text-blue-500">
                            {language === "gaia-en" ? "API Documentation" : "Documentación de API"}
                        </h2>
                        <div className="space-y-6">
                            <div className="space-y-2">
                                <h3 className="text-xl font-medium text-gray-300">
                                    {language === "gaia-en" ? "API Usage Example" : "Ejemplo de uso de API"}
                                </h3>
                                <div className="flex justify-between items-center mb-2">
                                    <span className="text-base text-gray-400">
                                        {language === "gaia-en" ? "Copy and use this model in your applications" : "Copie y use este modelo en sus aplicaciones"}
                                    </span>
                                    <button
                                        onClick={copyApiExample}
                                        className="flex items-center space-x-2 text-base text-gray-400 hover:text-blue-500"
                                    >
                                        {copied ? <Check size={16} /> : <Copy size={16} />}
                                        <span className="ml-1">
                                            {copied ?
                                                (language === "gaia-en" ? "Copied!" : "¡Copiado!") :
                                                (language === "gaia-en" ? "Copy" : "Copiar")
                                            }
                                        </span>
                                    </button>
                                </div>
                                <pre className="bg-gray-800 p-4 rounded-lg overflow-x-auto text-gray-300">
                                    <code>{`curl -X POST https://agentic.gaia-ml.com/api/translate/ \\
    -H "Content-Type: application/json" \\
    -H "X-API-Key: your_api_key_here" \\
    -d '{
        "text": "Hola a todos",
        "src_lang": "${srcLang}", 
        "tgt_lang": "${tgtLang}",
        "model_id": "${model?.id}"
    }'`}</code>
                                </pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicModel;