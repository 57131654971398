import React from 'react';
import { Shield, Lock, Scale, Server, Trash2 } from 'lucide-react';

const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
                    <p className="text-gray-400">Last updated: January 1, 2025</p>
                </div>

                <div className="bg-gray-800 rounded-xl border border-gray-700 shadow-xl mb-8 overflow-hidden">
                    <div className="p-6">
                        <div className="flex items-center space-x-2 mb-4">
                            <Scale className="text-indigo-400" size={24} />
                            <h2 className="text-xl font-semibold text-white">Ownership and Intellectual Property</h2>
                        </div>
                        <div className="text-gray-300 space-y-4">
                            <p>This Privacy Policy ("Policy") governs the relationship between Gaia, a company registered and operating under the laws of Peru ("Company," "we," "us," or "our"), and users of our translation agent building platform ("Users," "you," or "your"). All translation agents created through our platform ("Agents") are the exclusive intellectual property of their respective creators. Users retain full ownership rights over their Agents, including but not limited to usage, modification, distribution, and deletion rights.</p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-800 rounded-xl border border-gray-700 shadow-xl mb-8 overflow-hidden">
                    <div className="p-6">
                        <div className="flex items-center space-x-2 mb-4">
                            <Server className="text-indigo-400" size={24} />
                            <h2 className="text-xl font-semibold text-white">Data Management and Control</h2>
                        </div>
                        <div className="text-gray-300 space-y-4">
                            <p>Users maintain complete control over their data, including parallel language datasets, created Agents, and personal information. The Company acts solely as a service provider, offering the technical infrastructure for Agent creation and deployment. Users have the unilateral right to modify, export, or permanently delete their data at any time. We implement industry-standard security measures to protect user data, but Users remain responsible for maintaining the confidentiality of their access credentials and managing their Agent deployment.</p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-800 rounded-xl border border-gray-700 shadow-xl mb-8 overflow-hidden">
                    <div className="p-6">
                        <div className="flex items-center space-x-2 mb-4">
                            <Lock className="text-indigo-400" size={24} />
                            <h2 className="text-xl font-semibold text-white">User Responsibilities and Liabilities</h2>
                        </div>
                        <div className="text-gray-300 space-y-4">
                            <p>Users assume full responsibility for their use of the platform and any costs incurred thereof. This includes, but is not limited to, computational resources consumed, API usage, and any applicable service fees. The Company shall not be held liable for any consequences arising from the use, misuse, or performance of user-created Agents. Users are solely responsible for ensuring their Agents' compliance with applicable laws and regulations in their respective jurisdictions. We operate under Peruvian law and jurisdiction, and any disputes shall be resolved accordingly.</p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-800 rounded-xl border border-gray-700 shadow-xl mb-8 overflow-hidden">
                    <div className="p-6">
                        <div className="flex items-center space-x-2 mb-4">
                            <Trash2 className="text-indigo-400" size={24} />
                            <h2 className="text-xl font-semibold text-white">Termination and Data Deletion</h2>
                        </div>
                        <div className="text-gray-300 space-y-4">
                            <p>Users may terminate their relationship with the Company at any time by deleting their account and associated Agents. Upon deletion, all user data will be permanently removed from our active systems. The Company maintains no ownership claims over user-created Agents or associated data, and Users retain the right to deploy their Agents through alternative platforms or services. We may retain certain non-personally identifiable information for analytical purposes, subject to our data retention policies and applicable laws.</p>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-800 rounded-xl border border-gray-700 shadow-xl overflow-hidden">
                    <div className="p-6">
                        <div className="flex items-center space-x-2 mb-4">
                            <Shield className="text-indigo-400" size={24} />
                            <h2 className="text-xl font-semibold text-white">Legal Jurisdiction and Updates</h2>
                        </div>
                        <div className="text-gray-300 space-y-4">
                            <p>This Policy is governed by the laws of Peru, where the Company maintains its principal place of business. The Company reserves the right to update this Policy as necessary to reflect changes in our services or legal requirements. Users will be notified of any material changes to this Policy. By continuing to use our services after such modifications become effective, Users agree to be bound by the updated Policy. Any disputes arising under this Policy shall be resolved exclusively in the courts of Peru.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;