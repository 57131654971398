import React from 'react';
import { Upload, Sliders, Play, BarChart2, Key, Globe, Code, Zap } from 'lucide-react';

const EnhancedFeaturesMetrics = ({ language }) => {
    const Feature = ({ icon: Icon, title, description }) => (
        <div className="relative flex flex-col items-center text-center p-6 bg-gray-800 rounded-3xl shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
            <div className="absolute -top-6 -right-6 w-16 h-16 bg-gradient-to-tr from-blue-500 to-indigo-600 rounded-full blur-lg opacity-30"></div>
            <div className="p-3 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-full mb-4">
                <Icon size={28} className="text-white" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
            <p className="text-gray-400 text-base">{description}</p>
        </div>
    );

    const MetricsCard = () => (
        <div className="relative bg-gray-800 rounded-3xl shadow-lg p-6 transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
            <div className="absolute -top-8 -left-8 w-20 h-20 bg-gradient-to-br from-purple-600 to-pink-500 rounded-full blur-lg opacity-20"></div>
            <div className="flex items-center mb-6">
                <BarChart2 size={28} className="text-indigo-500 mr-3" />
                <h3 className="text-2xl font-semibold text-white">
                    {language === "gaia-en" ? "Performance Metrics" : "Métricas de Rendimiento"}
                </h3>
            </div>
            <div className="space-y-4">
                <div className="bg-gray-700 p-4 rounded-xl">
                    <h4 className="font-semibold mb-1 text-white">
                        {language === "gaia-en" ? "English to Saraiki" : "Inglés a Saraiki"}
                    </h4>
                    <p className="text-base text-gray-300">BLEU Score: 38.2</p>
                    <p className="text-base text-gray-300">chrF Score: 0.65</p>
                </div>
                <div className="bg-gray-700 p-4 rounded-xl">
                    <h4 className="font-semibold mb-1 text-white">
                        {language === "gaia-en" ? "Spanish to Aguaruna" : "Español a Aguaruna"}
                    </h4>
                    <p className="text-base text-gray-300">BLEU Score: 35.7</p>
                    <p className="text-base text-gray-300">chrF Score: 0.62</p>
                </div>
            </div>
        </div>
    );

    const ApiCard = () => (
        <div className="relative bg-gray-800 rounded-3xl shadow-lg p-6 transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
            <div className="absolute -bottom-8 -right-8 w-20 h-20 bg-gradient-to-br from-green-500 to-teal-500 rounded-full blur-lg opacity-20"></div>
            <div className="flex items-center mb-6">
                <Key size={28} className="text-green-500 mr-3" />
                <h3 className="text-2xl font-semibold text-white">
                    {language === "gaia-en" ? "API Access" : "Acceso API"}
                </h3>
            </div>
            <div className="bg-gray-700 p-4 rounded-xl overflow-x-auto">
                <pre className="text-xs text-green-300">
                    <code>
                        {`curl -X POST https://agentic.gaia-ml.com/api/translate/ \\
   -H "Content-Type: application/json" \\
   -H "X-API-Key: your_api_key_here" \\
   -d ''{
       "text": "Hola a todos",
       "src_lang": "spanish", 
       "tgt_lang": "aguaruna",
       "model_id": "91709254-975c-48d3-9fde-49fac3a7b2ea"
   }'`}
                    </code>
                </pre>
            </div>
        </div>
    );

    const features = [
        {
            icon: Upload,
            title: language === "gaia-en" ? "Easy Data Upload" : "Carga Fácil de Datos",
            description: language === "gaia-en"
                ? "Upload your parallel data JSON file with a simple drag-and-drop interface."
                : "Sube tu archivo JSON de datos paralelos con una simple interfaz de arrastrar y soltar."
        },
        {
            icon: Sliders,
            title: language === "gaia-en" ? "Data requirements" : "Requisitos de datos",
            description: language === "gaia-en"
                ? "Start building on Gaia with as low as 5 thousand parallel sentences"
                : "Comienza a construir en Gaia con tan solo 5 mil oraciones paralelas"
        },
        {
            icon: Play,
            title: language === "gaia-en" ? "Fast building" : "Construcción rápida",
            description: language === "gaia-en"
                ? "Have your agents ready almost in no time"
                : "Ten tus agentes listos casi al instante"
        },
        {
            icon: Globe,
            title: language === "gaia-en" ? "Multi-Language Support" : "Soporte Multilingüe",
            description: language === "gaia-en"
                ? "Build translation agents for low-resource languages"
                : "Construye agentes de traducción para idiomas de bajos recursos"
        },
        {
            icon: Code,
            title: language === "gaia-en" ? "Developer-Friendly" : "Amigable para Desarrolladores",
            description: language === "gaia-en"
                ? "Easily integrate your translation agents with our comprehensive API"
                : "Integra fácilmente tus agentes de traducción con nuestra API completa"
        }
    ];

    return (
        <div translate="no" className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-black opacity-80 pointer-events-none"></div>
            <h2 className="text-4xl font-bold text-center mb-12 text-white relative z-10">
                {language === "gaia-en" ? "Features & Metrics" : "Características y Métricas"}
            </h2>
            <section className="relative z-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
                {features.map((feature, index) => (
                    <Feature
                        key={index}
                        icon={feature.icon}
                        title={feature.title}
                        description={feature.description}
                    />
                ))}
            </section>

            <section className="relative z-10 grid grid-cols-1 md:grid-cols-2 gap-8">
                <MetricsCard />
                <ApiCard />
            </section>

            <div className="absolute top-0 left-0 w-64 h-64 bg-gradient-to-br from-blue-600 to-purple-600 opacity-30 rounded-full -mt-32 -ml-32 blur-2xl"></div>
            <div className="absolute bottom-0 right-0 w-64 h-64 bg-gradient-to-br from-green-600 to-teal-600 opacity-30 rounded-full -mb-32 -mr-32 blur-2xl"></div>
        </div>
    );
};

export default EnhancedFeaturesMetrics;