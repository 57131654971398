import React, { useState, useEffect } from 'react';
import { getAuth, updateProfile } from "firebase/auth";
import { Globe, Settings, BarChart, Users, User, PlusCircle, Trash2, Clock, Pen, ArrowLeft, Menu, X, ChevronRightSquare } from 'lucide-react';
import CreateModel from './createModel';
import {PiLightningABold} from "react-icons/pi"
import Training from './training';
import { Check, Copy } from 'lucide-react';
import { initializeApp } from "firebase/app";
import { Link } from 'react-router-dom';
import { getFirestore, collection, getDocs, query, where, getDoc, setDoc, addDoc, serverTimestamp, onSnapshot, doc, updateDoc, arrayRemove } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAOYiOFVlyrhxtWYxn8J4yvCbFOdSvXYkI",
    authDomain: "gaia-b6c3d.firebaseapp.com",
    projectId: "gaia-b6c3d",
    storageBucket: "gaia-b6c3d.appspot.com",
    messagingSenderId: "143445699815",
    appId: "1:143445699815:web:58ff7938215492a1414642",
    measurementId: "G-MPTVCFN0TJ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const Dashboard = ({ user, sotaModels, language }) => {
    const [activeSection, setActiveSection] = useState('Dashboard');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [totalRequests, setTotalRequests] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const SidebarItem = ({ icon: Icon, title }) => (
        <div
            className={`flex items-center p-3 text-gray-700 hover:bg-blue-100 cursor-pointer ${activeSection === title ? 'bg-blue-100' : ''}`}
            onClick={() => {
                setActiveSection(title);
                setIsSidebarOpen(false); 
            }}
        >
            <Icon size={20} className="mr-3" />
            <span>{title}</span>
        </div>
    );

    useEffect(() => {
        if (!user) return;

        const keysQuery = query(
            collection(db, 'api_keys'),
            where('user_id', '==', user.uid)
        );

        return onSnapshot(keysQuery, (keysSnapshot) => {

            const keyIds = keysSnapshot.docs.map(doc => doc.id);

            const usagesQuery = query(collection(db, 'key_usage'));

            onSnapshot(usagesQuery, (usageSnapshot) => {
                let totalRequests = 0;
                let totalInputTokens = 0;
                let totalOutputTokens = 0;

                usageSnapshot.forEach((doc) => {
                    if (keyIds.includes(doc.id)) {
                        const data = doc.data();
                        const requests = data.requests || [];

                        requests.forEach(req => {
                            totalInputTokens += req.input_tokens || 0;
                            totalOutputTokens += req.output_tokens || 0;
                        });

                        totalRequests += requests.length;
                    }
                });

                const INPUT_PRICE_PER_MILLION = 3;
                const OUTPUT_PRICE_PER_MILLION = 15;

                const inputCost = (totalInputTokens / 1_000_000) * INPUT_PRICE_PER_MILLION;
                const outputCost = (totalOutputTokens / 1_000_000) * OUTPUT_PRICE_PER_MILLION;
                const totalCost = inputCost + outputCost;

                setTotalRequests(totalRequests);
                setTotalCost(totalCost.toFixed(6));
            });
        });
    }, [user]);

    const StatCard = ({ title, value }) => (
        <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-base text-gray-800">{title}</h3>
            <p className="text-2xl font-semibold">{value}</p>
        </div>
    );

    const renderActiveSection = () => {

        switch (activeSection) {
            case 'Builder':
                return <CreateModel language={language} user={user} sotaModels={sotaModels} />;
            case 'API':
                return <API models={sotaModels} language={language} />;
            case 'Team':
                return <Team language={language} />;
            case 'Account':
                return <Account user={user} language={language} />;
            default: 
                return (
                    <div className="flex-1 p-4 sm:p-8 overflow-auto">
                        <div className="flex justify-between items-center mb-6">
                            <h1 className="text-2xl font-semibold">{language === "gaia-en" ? "Welcome, " : "Bienvenido, "} {user?.displayName}</h1>
                        </div>
                        <div className="grid grid-cols-1 text-gray-800 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                            <StatCard title={language === "gaia-en" ? "Active Agents" : "Agentes Activos"} value={sotaModels.length} />
                            <StatCard title={language === "gaia-en" ? "Languages" : "Idiomas"} value={sotaModels.length * 2} />
                            <StatCard title={language === "gaia-en" ? "Total API Requests" : "Total de Solicitudes API"} value={totalRequests} />
                            <StatCard title={language === "gaia-en" ? "Total Cost" : "Costo Total"} value={`$${totalCost}`} />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                            <div className="bg-white p-4 rounded-lg shadow flex items-center justify-between">
                                <div onClick={() => setActiveSection("Training")} className="flex cursor-pointer items-center">
                                    <PlusCircle size={24} className="mr-3 text-blue-600" />
                                    <span>{language === "gaia-en" ? "New model" : "Nuevo modelo"}</span>
                                </div>
                                <button onClick={() => setActiveSection("Builder")} className="bg-blue-500 text-white px-4 py-2 rounded font-semibold hover:bg-blue-600">{language === "gaia-en" ? "Create" : "Crear"}</button>
                            </div>
                        </div> 
                        <div className="grid grid-cols-1 space-x-4 space-y-4 p-4 sm:grid-cols-2">
                            {sotaModels.map((model) => (
                                <ModelCard key={model.id} language={language} model={model} />
                            ))}
                        </div>
                    </div>
                );
        }
    };

    return (
        <div translate="no" className="flex h-screen bg-gray-100">
            {isSidebarOpen && (
                <div className="fixed inset-0 z-40 sm:hidden">
                    <div className="absolute inset-0 bg-black opacity-50" onClick={toggleSidebar}></div>
                    <div className="absolute inset-y-0 left-0 w-64 bg-white shadow-lg p-4">
                        <div className="flex items-center justify-between mb-6">
                            <h2 className="text-xl font-semibold">{language === "gaia-en" ? "Menu" : "Menú"}</h2>
                            <button onClick={toggleSidebar}>
                                <X size={24} />
                            </button>
                        </div>
                        <nav>
                            <SidebarItem icon={Globe} title="Dashboard" />
                            <SidebarItem icon={PiLightningABold} title="Builder" />
                            <SidebarItem icon={BarChart} title="API" />
                            <SidebarItem icon={Users} title="Team" />
                            <SidebarItem icon={User} title="Account" />
                        </nav>
                    </div>
                </div>
            )}

            <div className="hidden sm:flex sm:flex-shrink-0">
                <div className="w-64 bg-white shadow-lg h-full">
                    <nav className="mt-20">
                        <SidebarItem icon={Globe} title="Dashboard" />
                        <SidebarItem icon={PiLightningABold} title="Builder" />
                        <SidebarItem icon={BarChart} title="API" />
                        <SidebarItem icon={Users} title="Team" />
                        <SidebarItem icon={User} title="Account" />
                    </nav>
                </div>
            </div>

            <div className="flex-1 flex flex-col overflow-hidden">
                <header className="flex items-center justify-between p-4 bg-white shadow-sm sm:hidden">
                    <button onClick={toggleSidebar}>
                        <ChevronRightSquare size={24} />
                    </button>
                    <h1 className="text-xl font-semibold">Dashboard</h1>
                    <div></div>
                </header>

                <main className="flex-1 overflow-y-auto bg-white">
                    {renderActiveSection()}
                </main>
            </div>
        </div>
    );
};

export default Dashboard;

const API = ({ models, language }) => {
    const [selectedModel, setSelectedModel] = useState('');
    const [copied, setCopied] = useState(false);
    const [usageStats, setUsageStats] = useState(null);
    const [loading, setLoading] = useState(false);
    const [apiKeys, setApiKeys] = useState([]);
    const [newKeyName, setNewKeyName] = useState('');
    const [keyUsage, setKeyUsage] = useState({});
    const [creatingKey, setCreatingKey] = useState(false);
    const [newlyCreatedKey, setNewlyCreatedKey] = useState(null);

    const INPUT_PRICE_PER_MILLION = 3;
    const OUTPUT_PRICE_PER_MILLION = 15;

    const modelOptions = models?.map(model => ({
        value: model.id,
        label: `${model.src_lang} → ${model.tgt_lang}`,
        ...model
    })) || [];

    useEffect(() => {
        fetchApiKeys();
    }, []);

    const fetchApiKeys = async () => {
        try {
            const user = auth.currentUser;
            if (!user) return;

            const response = await fetch(`https://agentic.gaia-ml.com/api/api-keys/list/?uid=${user.uid}`);
            const data = await response.json();
            if (data.success) {
                setApiKeys(data.api_keys);
                // Fetch usage for each key
                data.api_keys.forEach(key => fetchKeyUsage(key.key_id));
            }
        } catch (error) {
        }
    };

    const fetchKeyUsage = async (keyId) => {
        try {
            const user = auth.currentUser;
            if (!user) return;

            const response = await fetch(`https://agentic.gaia-ml.com/api/api-keys/usage/?key_id=${keyId}&uid=${user.uid}`);
            const data = await response.json();
            if (data.success) {
                setKeyUsage(prev => ({
                    ...prev,
                    [keyId]: data.usage
                }));
            }
        } catch (error) {
        }
    };

    const createApiKey = async () => {
        try {
            setCreatingKey(true);
            const user = auth.currentUser;
            if (!user) return;

            const response = await fetch('https://agentic.gaia-ml.com/api/api-keys/create/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: user.uid,
                    name: newKeyName
                })
            });

            const data = await response.json();
            if (data.success) {
                setNewlyCreatedKey(data.api_key); // Store the new key
                setNewKeyName('');
                fetchApiKeys();
            }
        } catch (error) {
        } finally {
            setCreatingKey(false);
        }
    };

    const revokeApiKey = async (keyId) => {
        try {
            const user = auth.currentUser;
            if (!user) return;

            const response = await fetch('https://agentic.gaia-ml.com/api/api-keys/revoke/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: user.uid,
                    key_id: keyId
                })
            });

            if (response.ok) {
                fetchApiKeys();
            }
        } catch (error) {
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const getApiExample = (model) => {
        return `curl -X POST https://agentic.gaia-ml.com/api/translate/ \\
    -H "Content-Type: application/json" \\
    -H "X-API-Key: your_api_key_here" \\
    -d '{
        "text": "Hola a todos",
        "src_lang": "${model?.src_lang}", 
        "tgt_lang": "${model?.tgt_lang}",
        "model_id": "${model?.model_id}"
    }'`;
    };

    const selectedModelData = modelOptions.find(m => m.value === selectedModel);
    const apiExample = getApiExample(selectedModelData);

    return (
        <div className="p-8 mt-20 max-w-6xl mx-auto">
            <div className="bg-white rounded-xl shadow-lg mb-8 overflow-hidden">
                <div className="border-b border-gray-100 p-6">
                    <h3 className="text-xl font-bold text-gray-800">{language === "gaia-en" ? "API Keys" : "Claves API"}</h3>
                </div>

                {/* Create New API Key */}
                <div className="p-6 bg-gray-50 border-b">
                    <div className="flex gap-4">
                        <input
                            type="text"
                            value={newKeyName}
                            onChange={(e) => setNewKeyName(e.target.value)}
                            placeholder={language === "gaia-en" ? "Enter key name (e.g., Production, Development)" : "Ingrese nombre de clave (ej: Producción, Desarrollo)"}
                            className="flex-1 border-2 border-blue-500 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                        <button
                            onClick={createApiKey}
                            disabled={creatingKey || !newKeyName}
                            className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 
                                     disabled:opacity-50 transition-all duration-200 font-medium"
                        >
                            {creatingKey ? (
                                <span className="flex items-center gap-2">
                                    <div className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full"></div>
                                    {language === "gaia-en" ? "Creating..." : "Creando..."}
                                </span>
                            ) : language === "gaia-en" ? "Create API Key" : "Crear Clave API"}
                        </button>
                    </div>
                </div>

                {/* API Keys List */}
                <div className="p-6 space-y-4">
                    {apiKeys.map((key) => (
                        <div key={key.key_id} className="bg-white border rounded-xl p-6 hover:shadow-md transition-shadow duration-200">
                            <div className="flex justify-between items-start mb-4">
                                <div>
                                    <h4 className="text-xl font-semibold text-gray-800">{key.name}</h4>
                                    <p className="text-base text-gray-500">{language === "gaia-en" ? "Created: " : "Creado: "} {new Date(key.created_at).toLocaleDateString()}</p>
                                </div>
                                <button
                                    onClick={() => revokeApiKey(key.key_id)}
                                    className="text-red-500 hover:text-red-700 hover:bg-red-50 p-2 rounded-lg transition-colors duration-200"
                                >
                                    <Trash2 size={20} />
                                </button>
                            </div>
                            
                            {keyUsage[key.key_id] && (
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                    <div className="bg-blue-50 rounded-lg p-4">
                                        <h5 className="font-medium text-blue-900 mb-2">{language === "gaia-en" ? "Usage Stats" : "Estadísticas de Uso"}</h5>
                                        <div className="space-y-2 text-base text-blue-800">
                                            <p className="flex justify-between">
                                                <span>{language === "gaia-en" ? "Total Requests:" : "Solicitudes Totales:"}</span>
                                                <span className="font-medium">{keyUsage[key.key_id].total_requests}</span>
                                            </p>
                                            <p className="flex justify-between">
                                                <span>{language === "gaia-en" ? "Daily Rate:" : "Tasa Diaria:"}</span>
                                                <span className="font-medium">
                                                    {keyUsage[key.key_id].rate_limit}
                                                </span>
                                            </p>
                                            <p className="flex justify-between">
                                                <span>{language === "gaia-en" ? "Avg. Tokens/Request:" : "Promedio Tokens/Solicitud:"}</span>
                                                <span className="font-medium">
                                                    {keyUsage[key.key_id].average_tokens_per_request}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    
                                    <div className="bg-green-50 rounded-lg p-4">
                                        <h5 className="font-medium text-green-900 mb-2">{language === "gaia-en" ? "Cost Overview" : "Resumen de Costos"}</h5>
                                        <div className="space-y-2 text-base text-green-800">
                                            <p className="flex justify-between">
                                                <span>{language === "gaia-en" ? "Input Tokens:" : "Tokens de Entrada:"}</span>
                                                <span className="font-medium">${keyUsage[key.key_id].input_cost}</span>
                                            </p>
                                            <p className="flex justify-between">
                                                <span>{language === "gaia-en" ? "Output Tokens:" : "Tokens de Salida:"}</span>
                                                <span className="font-medium">${keyUsage[key.key_id].output_cost}</span>
                                            </p>
                                            <p className="flex justify-between font-medium">
                                                <span>{language === "gaia-en" ? "Total Cost:" : "Costo Total:"}</span>
                                                <span>${keyUsage[key.key_id].total_cost}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Model Selection and API Example */}
            <div className="bg-white rounded-xl mb-12 shadow-lg overflow-hidden">
                <div className="border-b border-gray-100 p-6">
                    <h3 className="text-xl font-bold text-gray-800">{language === "gaia-en" ? "API Integration" : "Integración API"}</h3>
                </div>
                
                <div className="p-6">
                    <div className="space-y-6">
                        <div>
                            <label className="block text-base font-medium text-gray-700 mb-2">
                                {language === "gaia-en" ? "Select Translation Model" : "Seleccionar Modelo de Traducción"}
                            </label>
                            <select
                                value={selectedModel}
                                onChange={(e) => setSelectedModel(e.target.value)}
                                className="w-full border-2 border-blue-500 rounded-lg p-3 
                                         focus:outline-none"
                            >
                                <option value="">{language === "gaia-en" ? "Select a model" : "Seleccione un modelo"}</option>
                                {modelOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {selectedModel && (
                            <div className="bg-gray-50 rounded-xl p-6">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-xl font-semibold text-gray-800">{language === "gaia-en" ? "API Example" : "Ejemplo de API"}</h3>
                                    <button
                                        onClick={() => copyToClipboard(apiExample)}
                                        className="flex items-center gap-2 text-blue-600 hover:text-blue-800 
                                                 px-3 py-2 rounded-lg hover:bg-blue-50 transition-colors duration-200"
                                    >
                                        {copied ? <Check size={16} /> : <Copy size={16} />}
                                        <span>{copied ? language === "gaia-en" ? "Copied!" : "¡Copiado!" : language === "gaia-en" ? "Copy" : "Copiar"}</span>
                                    </button>
                                </div>
                                <pre className="bg-gray-900 text-gray-100 p-6 rounded-lg overflow-x-auto">
                                    <code>{apiExample}</code>
                                </pre>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* New API Key Modal */}
            {newlyCreatedKey && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6 m-4">
                        <h3 className="text-xl font-bold text-gray-800 mb-4">{language === "gaia-en" ? "Your New API Key" : "Su Nueva Clave API"}</h3>
                        <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
                            <p className="text-yellow-800 font-medium">
                                {language === "gaia-en" ? "Save this key now! You won't be able to see it again." : "¡Guarde esta clave ahora! No podrá verla nuevamente."}
                            </p>
                        </div>
                        <div className="bg-gray-900 text-gray-100 p-4 rounded-lg flex justify-between items-center mb-6">
                            <code className="text-base break-all">{newlyCreatedKey}</code>
                            <button
                                onClick={() => copyToClipboard(newlyCreatedKey)}
                                className="ml-4 p-2 hover:bg-gray-800 rounded-lg transition-colors duration-200"
                            >
                                {copied ? <Check size={16} /> : <Copy size={16} />}
                            </button>
                        </div>
                        <button
                            onClick={() => setNewlyCreatedKey(null)}
                            className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg 
                                     hover:bg-blue-700 transition-colors duration-200 font-medium"
                        >
                            {language === "gaia-en" ? "I've saved my key" : "He guardado mi clave"}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

const Team = ({ language }) => {
    const [newEditor, setNewEditor] = useState({ name: '', email: '' });
    const [teamMembers, setTeamMembers] = useState([]);
    const [error, setError] = useState('');

    // Translations
    const translations = {
        teams: language === "gaia-en" ? "Teams" : "Equipos",
        yourTeam: language === "gaia-en" ? "Your Team" : "Tu Equipo",
        manageTeamMembers: language === "gaia-en" ? "Manage your team members here." : "Gestiona los miembros de tu equipo aquí.",
        addNewEditor: language === "gaia-en" ? "Add New Editor" : "Agregar Nuevo Editor",
        inviteTeamMember: language === "gaia-en" ? "Invite a new team member as an editor." : "Invita a un nuevo miembro como editor.",
        name: language === "gaia-en" ? "Name" : "Nombre",
        email: language === "gaia-en" ? "Email" : "Correo electrónico",
        addEditor: language === "gaia-en" ? "Add Editor" : "Agregar Editor",
        editor: language === "gaia-en" ? "Editor" : "Editor",
        // Error messages
        fillAllFields: language === "gaia-en" ? "Please fill in all fields." : "Por favor complete todos los campos.",
        failedToAdd: language === "gaia-en" ? "Failed to add editor. Please try again." : "Error al agregar editor. Por favor intente nuevamente.",
        failedToRemove: language === "gaia-en" ? "Failed to remove editor. Please try again." : "Error al eliminar editor. Por favor intente nuevamente."
    };

    const fetchTeams = async () => {
        const user = auth.currentUser;
        if (user) {
            const q = query(collection(db, 'teams'), where('members', 'array-contains', user.uid));
            const querySnapshot = await getDocs(q);
            const members = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setTeamMembers(members);
        }
    };

    useEffect(() => {
        fetchTeams();
    }, []);

    const handleInputChange = (e) => {
        setNewEditor({ ...newEditor, [e.target.name]: e.target.value });
    };

    const handleAddEditor = async (e) => {
        e.preventDefault();
        setError('');

        if (!newEditor.name || !newEditor.email) {
            setError(translations.fillAllFields);
            return;
        }

        try {
            const user = auth.currentUser;
            await addDoc(collection(db, 'teams'), {
                ownerId: user.uid,
                members: [user.uid],
                name: newEditor.name,
                email: newEditor.email,
                role: translations.editor
            });

            setNewEditor({ name: '', email: '' });
            fetchTeams();
        } catch (error) {
            setError(translations.failedToAdd);
        }
    };

    const handleRemoveEditor = async (editorId) => {
        try {
            const user = auth.currentUser;
            const teamDoc = doc(db, 'teams', editorId);
            await updateDoc(teamDoc, {
                members: arrayRemove(user.uid)
            });
            fetchTeams();
        } catch (error) {
            setError(translations.failedToRemove);
        }
    };

    return (
        <div className="p-6 mt-20">
            <h2 className="text-2xl font-semibold mb-4">{translations.teams}</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl leading-6 font-medium text-gray-900">{translations.yourTeam}</h3>
                    <p className="mt-1 max-w-2xl text-base text-gray-500">{translations.manageTeamMembers}</p>
                </div>
                <div className="border-t border-gray-200">
                    <ul className="divide-y divide-gray-200">
                        {teamMembers.map((member) => (
                            <li key={member.id} className="px-4 py-4 sm:px-6">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                                        <div className="ml-4">
                                            <div className="text-base font-medium text-gray-900">{member.name}</div>
                                            <div className="text-base text-gray-500">{member.email}</div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="text-base text-gray-500 mr-4">{member.role}</div>
                                        {member.ownerId !== auth.currentUser.uid && (
                                            <button
                                                onClick={() => handleRemoveEditor(member.id)}
                                                className="text-red-600 hover:text-red-800"
                                            >
                                                <Trash2 size={20} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl leading-6 font-medium text-gray-900">{translations.addNewEditor}</h3>
                    <p className="mt-1 max-w-2xl text-base text-gray-500">{translations.inviteTeamMember}</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <form onSubmit={handleAddEditor} className="space-y-4">
                        <div>
                            <label htmlFor="name" className="block text-base font-medium text-gray-700">
                                {translations.name}
                            </label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                value={newEditor.name}
                                onChange={handleInputChange}
                                className="mt-1 focus:ring-indigo-500 p-2 border border-indigo-300 outline-none focus:border-indigo-500 block w-full shadow-sm sm:text-base border-gray-300 rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-base font-medium text-gray-700">
                                {translations.email}
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={newEditor.email}
                                onChange={handleInputChange}
                                className="mt-1 focus:ring-indigo-500 p-2 border border-indigo-300 outline-none focus:border-indigo-500 block w-full shadow-sm sm:text-base border-gray-300 rounded-md"
                            />
                        </div>
                        {error && <p className="text-red-500 text-base">{error}</p>}
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {translations.addEditor}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

const Account = ({ user, language }) => {
    const [editMode, setEditMode] = useState({
        email: false,
        fullName: false,
    });
    const [userData, setUserData] = useState({
        email: user?.email,
        fullName: user?.displayName,
    });

    // Translations
    const translations = {
        accountSettings: language === "gaia-en" ? "Account Settings" : "Configuración de la Cuenta",
        profile: language === "gaia-en" ? "Profile" : "Perfil",
        manageAccount: language === "gaia-en" ? "Manage your account information here." : "Gestione la información de su cuenta aquí.",
        contactEmail: language === "gaia-en" ? "Contact Email" : "Correo de Contacto",
        fullName: language === "gaia-en" ? "Full Name" : "Nombre Completo",
        save: language === "gaia-en" ? "Save" : "Guardar",
        edit: language === "gaia-en" ? "Edit" : "Editar"
    };

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setUserData(prevState => ({
                        ...prevState,
                    }));
                }
            }
        };
        fetchUserData();
    }, [user]);

    const handleEdit = (field) => {
        setEditMode({ ...editMode, [field]: true });
    };

    const handleSave = async (field) => {
        setEditMode({ ...editMode, [field]: false });
        const userDocRef = doc(db, 'users', user.uid);

        try {
            if (field === 'fullName') {
                await updateProfile(auth.currentUser, { displayName: userData.fullName });
            }
            await updateDoc(userDocRef, { [field]: userData[field] });
        } catch (error) {
            // Error handling could be added here with translations
        }
    };

    const handleChange = (e, field) => {
        setUserData({ ...userData, [field]: e.target.value });
    };

    const renderField = (field, value) => {
        const label = field === 'email' ? translations.contactEmail : translations.fullName;

        return (
            <li className="px-4 py-4 mt-20 sm:px-6">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <div className="text-base font-medium text-gray-900">{label}</div>
                    </div>
                    <div className="flex items-center">
                        {editMode[field] ? (
                            <>
                                <input
                                    type="text"
                                    value={value}
                                    onChange={(e) => handleChange(e, field)}
                                    className="text-base text-gray-500 mr-2 border rounded px-2 py-1"
                                />
                                <button
                                    onClick={() => handleSave(field)}
                                    className="text-green-600 hover:text-green-800"
                                >
                                    {translations.save}
                                </button>
                            </>
                        ) : (
                            <>
                                <div className="text-base text-gray-500 mr-2">{value}</div>
                                <button
                                    onClick={() => handleEdit(field)}
                                    className="text-blue-600 hover:text-blue-800"
                                    aria-label={translations.edit}
                                >
                                    <Pen size={16} />
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </li>
        );
    };

    return (
        <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">{translations.accountSettings}</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl leading-6 font-medium text-gray-900">{translations.profile}</h3>
                    <p className="mt-1 max-w-2xl text-base text-gray-500">{translations.manageAccount}</p>
                </div>
                <div className="border-t border-gray-200">
                    <ul className="divide-y divide-gray-200">
                        {renderField('email', userData.email)}
                        {renderField('fullName', userData.fullName)}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const toggleStyles = `
  .toggle-checkbox:checked {
    right: 0;
    border-color: #68D391;
  }
  .toggle-checkbox:checked + .toggle-label {
    background-color: #68D391;
  }
`;

const ModelCard = ({ model, language }) => {
    return (
        <Link
            to={`/dashboard/${model.id}`}
            key={model.id}
            className="p-6 bg-white rounded-xl shadow-sm border my-2 border-indigo-100 hover:border-indigo-300 cursor-pointer transition"
        >
            <div className="sm:flex sm:justify-between items-start">
                <div className="flex-1">
                    <div className="flex items-center gap-2">
                        <h3 className="text-xl font-medium text-indigo-600">
                            {model.src_lang} → {model.tgt_lang}
                        </h3>
                    </div>
<div className='py-2 flex items-center space-x-2'>
                        <div
                            className={`w-2 h-2 rounded-full ${model.public ? 'bg-green-500' : 'bg-red-500'}`}
                        ></div>
                        <p>{`${model.public ? "Public" : "Private"} model`}</p>
</div>
                    <p className="text-base text-gray-600 mt-1">
                        {language==="gaia-en"? "Created: ": "Creado: "} {new Date(model.created_at.seconds * 1000).toLocaleDateString()}
                    </p>
                </div>

                {model.custom_prompt && (
                    <div className="sm:ml-4 max-w-xs">
                        <p className="text-base line-clamp-5 text-gray-800 sm:text-right">
                            {model.custom_prompt}
                        </p>
                    </div>
                )}
            </div>
        </Link>
    );
};