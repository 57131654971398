import React from 'react';
import { Book, Brain, Database, FlaskConical, Network, Search, Share2 } from 'lucide-react';
import Footer from "./footer"

const Docs = ({language}) => {
    const translations = {
        title: language === "gaia-en"
            ? "Scientific Architecture: Gaia Translation System"
            : "Arquitectura Científica: Sistema de Traducción Gaia",

        intro: language === "gaia-en"
            ? "Gaia implements a novel approach to low-resource language translation, leveraging Large Language Models (LLMs) with specialized memory architecture and retrieval mechanisms."
            : "Gaia implementa un enfoque novedoso para la traducción de lenguas de bajos recursos, aprovechando los Modelos de Lenguaje Grandes (LLMs) con arquitectura de memoria especializada y mecanismos de recuperación.",

        researchFoundation: language === "gaia-en" ? "Research Foundation" : "Base de Investigación",

        researchDesc: language === "gaia-en"
            ? "Based on groundbreaking research from Peking University (2024), introducing DiPMT++, a framework enabling LLMs to learn unseen languages through In-Context Learning (ICL). Using only a dictionary and 5,000 parallel sentences, the system achieves remarkable translation performance."
            : "Basado en investigación innovadora de la Universidad de Pekín (2024), que introduce DiPMT++, un marco que permite a los LLMs aprender idiomas no vistos a través del Aprendizaje en Contexto (ICL). Usando solo un diccionario y 5,000 oraciones paralelas, el sistema logra un rendimiento de traducción notable.",

        publicationDetails: language === "gaia-en" ? "Publication Details" : "Detalles de Publicación",
        title_label: language === "gaia-en" ? "Title" : "Título",
        authors: language === "gaia-en" ? "Authors" : "Autores",
        publication: language === "gaia-en" ? "Publication" : "Publicación",
        paper: language === "gaia-en" ? "Paper" : "Artículo",

        metrics: language === "gaia-en" ? "Key Performance Metrics" : "Métricas de Rendimiento Clave",
        innovations: language === "gaia-en" ? "Key Innovations" : "Innovaciones Clave",

        lexicalCoverage: {
            title: language === "gaia-en" ? "Improved Lexical Coverage" : "Cobertura Léxica Mejorada",
            desc: language === "gaia-en"
                ? "Enhanced dictionary coverage through fuzzy matching, bilingual lexicon induction, and synonym expansion"
                : "Cobertura mejorada del diccionario mediante coincidencia difusa, inducción de léxico bilingüe y expansión de sinónimos"
        },

        syntacticLearning: {
            title: language === "gaia-en" ? "Syntactic Learning" : "Aprendizaje Sintáctico",
            desc: language === "gaia-en"
                ? "Dynamic exemplar selection using BM25 retrieval for improved syntactic understanding"
                : "Selección dinámica de ejemplares usando recuperación BM25 para mejor comprensión sintáctica"
        },

        otherPapers: language === "gaia-en"
            ? "Other interesting papers advancing low-resource translation include:"
            : "Otros artículos interesantes que avanzan en la traducción de bajos recursos incluyen:",

        memoryArchitecture: language === "gaia-en" ? "Gaia Memory Architecture" : "Arquitectura de Memoria de Gaia",

        coreMemory: {
            title: language === "gaia-en" ? "Core Memory" : "Memoria Central",
            intro: language === "gaia-en" ? "Permanent storage of fundamental language patterns like:" : "Almacenamiento permanente de patrones fundamentales del lenguaje como:",
            items: language === "gaia-en"
                ? ["Grammar rules", "Pronoun systems", "Verb conjugations", "Question formations", "And more"]
                : ["Reglas gramaticales", "Sistemas de pronombres", "Conjugaciones verbales", "Formación de preguntas", "Y más"]
        },

        archivalMemory: {
            title: language === "gaia-en" ? "Archival Memory" : "Memoria de Archivo",
            intro: language === "gaia-en" ? "Storage of:" : "Almacenamiento de:",
            items: language === "gaia-en"
                ? ["Parallel sentences", "Vocabulary mappings", "Missing word tracking"]
                : ["Oraciones paralelas", "Mapeos de vocabulario", "Seguimiento de palabras faltantes"]
        },

        retrievalMechanism: {
            title: language === "gaia-en" ? "Retrieval Mechanism" : "Mecanismo de Recuperación",
            intro: language === "gaia-en" ? "Hybrid retrieval system combining:" : "Sistema híbrido de recuperación que combina:",
            search: {
                title: language === "gaia-en" ? "Keyword Search" : "Búsqueda por Palabras Clave",
                desc: language === "gaia-en"
                    ? "Custom algorithm optimized for low-resource languages where traditional semantic search fails"
                    : "Algoritmo personalizado optimizado para lenguas de bajos recursos donde la búsqueda semántica tradicional falla"
            },
            rag: {
                title: language === "gaia-en" ? "RAG Integration" : "Integración RAG",
                desc: language === "gaia-en"
                    ? "Retrieval Augmented Generation for relevant parallel sentence selection"
                    : "Generación Aumentada por Recuperación para la selección de oraciones paralelas relevantes"
            }
        },

        modelSelection: {
            title: language === "gaia-en" ? "Model Selection" : "Selección de Modelo",
            desc: language === "gaia-en"
                ? "Extensive testing revealed Anthropic's models (Opus and Sonnet 3.5) outperform alternatives:"
                : "Las pruebas extensivas revelaron que los modelos de Anthropic (Opus y Sonnet 3.5) superan las alternativas:",
            points: language === "gaia-en"
                ? [
                    "Superior to GPT-3.5, GPT-4, Qwen-chat, Llama-2-chat in low-resource translation",
                    "More effective than OpenAI's fine-tuning approach for unseen languages"
                ]
                : [
                    "Superior a GPT-3.5, GPT-4, Qwen-chat, Llama-2-chat en traducción de bajos recursos",
                    "Más efectivo que el enfoque de fine-tuning de OpenAI para lenguas no vistas"
                ]
        },

        apiIntegration: {
            title: language === "gaia-en" ? "API Integration" : "Integración de API",
            desc: language === "gaia-en"
                ? "RESTful API endpoint with standardized language codes:"
                : "Endpoint de API RESTful con códigos de idioma estandarizados:",
            format: language === "gaia-en" ? "Format:" : "Formato:"
        }
    };

    return (
        <div translate="no" className='bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white min-h-screen'>
            <div className="max-w-4xl pt-8 md:pt-16 mx-auto px-4 md:p-6">
                <section className="mb-8 md:mb-12">
                    <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6">{translations.title}</h1>
                    <p className="text-gray-300 mb-4">{translations.intro}</p>
                </section>

                <section className="mb-8 md:mb-12 bg-gray-800/50 p-4 md:p-6 rounded-xl">
                    <div className="flex items-center mb-4">
                        <FlaskConical className="w-6 h-6 mr-3 text-blue-400" />
                        <h2 className="text-xl md:text-2xl font-semibold">{translations.researchFoundation}</h2>
                    </div>
                    <div className="pl-4 md:pl-9">
                        <p className="text-gray-300 mb-4">{translations.researchDesc}</p>
                        <div className="bg-gray-700/50 p-4 rounded-lg mb-4">
                            <h3 className="text-xl font-medium text-blue-300 mb-2">{translations.publicationDetails}</h3>
                            <div className="text-gray-300">
                                <p className="mb-2"><span className="text-blue-200">{translations.title_label}:</span> Teaching Large Language Models an Unseen Language on the Fly</p>
                                <p className="mb-2"><span className="text-blue-200">{translations.authors}:</span> Chen Zhang, Xiao Liu, Jiuheng Lin, Yansong Feng</p>
                                <p className="mb-2"><span className="text-blue-200">{translations.publication}:</span> ACL 2024 Findings</p>
                                <p><span className="text-blue-200">{translations.paper}:</span> <a href="https://arxiv.org/pdf/2402.19167" className="text-blue-400 hover:underline" target="_blank" rel="noopener noreferrer">arxiv.org/pdf/2402.19167</a></p>
                            </div>
                        </div>

                        <div className="bg-gray-700/50 p-4 rounded-lg mb-4">
                            <h3 className="text-xl font-medium text-blue-300 mb-2">{translations.metrics}</h3>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div>
                                    <p className="text-base text-gray-400">Zhuang → Chinese</p>
                                    <p className="text-xl">BLEU: 31.9</p>
                                </div>
                                <div>
                                    <p className="text-base text-gray-400">Chinese → Zhuang</p>
                                    <p className="text-xl">BLEU: 15.7</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-gray-700/50 p-4 rounded-lg">
                            <h3 className="text-xl font-medium text-blue-300 mb-2">{translations.innovations}</h3>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div>
                                    <p className="text-base font-medium text-blue-200">{translations.lexicalCoverage.title}</p>
                                    <p className="text-gray-300">{translations.lexicalCoverage.desc}</p>
                                </div>
                                <div>
                                    <p className="text-base font-medium text-blue-200">{translations.syntacticLearning.title}</p>
                                    <p className="text-gray-300">{translations.syntacticLearning.desc}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Memory Architecture Section */}
                <section className="mb-8 md:mb-12 bg-gray-800/50 p-4 md:p-6 rounded-xl">
                    <div className="flex items-center mb-4">
                        <Brain className="w-6 h-6 mr-3 text-blue-400" />
                        <h2 className="text-xl md:text-2xl font-semibold">{translations.memoryArchitecture}</h2>
                    </div>
                    <div className="pl-4 md:pl-9">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6 mb-6">
                            <div className="bg-gray-700/50 p-4 rounded-lg">
                                <h3 className="text-xl font-medium text-blue-300 mb-2">{translations.coreMemory.title}</h3>
                                <p className="text-gray-300">{translations.coreMemory.intro}</p>
                                <ul className="list-disc pl-5 text-gray-300 mt-2">
                                    {translations.coreMemory.items.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="bg-gray-700/50 p-4 rounded-lg">
                                <h3 className="text-xl font-medium text-blue-300 mb-2">{translations.archivalMemory.title}</h3>
                                <p className="text-gray-300">{translations.archivalMemory.intro}</p>
                                <ul className="list-disc pl-5 text-gray-300 mt-2">
                                    {translations.archivalMemory.items.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                {/* API Integration Section */}
                <section className="bg-gray-800/50 p-4 md:p-6 rounded-xl">
                    <div className="flex items-center mb-4">
                        <Share2 className="w-6 h-6 mr-3 text-blue-400" />
                        <h2 className="text-xl md:text-2xl font-semibold">{translations.apiIntegration.title}</h2>
                    </div>
                    <div className="pl-4 md:pl-9">
                        <p className="text-gray-300 mb-4">{translations.apiIntegration.desc}</p>
                        <div className="bg-gray-700/50 p-4 rounded-lg overflow-x-auto">
                            <p className="text-gray-400 mt-2">{translations.apiIntegration.format}</p>
                            <pre className="text-blue-300 text-sm mt-1">
                                {`curl -X POST https://agentic.gaia-ml.com/api/translate/ \\
    -H "Content-Type: application/json" \\
    -H "X-API-Key: your_api_key_here" \\
    -d ''{ 
        "text": "Hola a todos",
        "src_lang": "spanish", 
        "tgt_lang": "aguaruna",
        "model_id": "91709254-975c-48d3-9fde-49fac3a7b2ea"
    }'`}
                            </pre>
                        </div>
                    </div>
                </section>
            </div>
            <Footer language={language} />
        </div>
    );
};

export default Docs;