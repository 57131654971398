import React from 'react';
import { Heart, Github } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = ({ language }) => {
    const currentYear = new Date().getFullYear();

    return (
        <footer translate="no" className="relative bg-gradient-to-r from-gray-900 via-gray-800 to-black py-8 mt-12 text-white">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-900 via-transparent to-purple-900 opacity-20 pointer-events-none"></div>
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
                <div className="flex flex-col items-center justify-between space-y-4 sm:space-y-0 sm:flex-row">
                    <div className="sm:flex items-center sm:space-x-10">
                        <p className="text-gray-400 text-base sm:mb-0">
                            © {currentYear} {language === "gaia-en"
                                ? "Gaia. All rights reserved."
                                : "Gaia. Todos los derechos reservados."
                            }
                        </p>
                        <Link
                            to="/privacy"
                            className="text-gray-400 flex justify-center mt-4 sm:mt-0 hover:text-indigo-400 transition-colors duration-200"
                        >
                            {language === "gaia-en" ? "Privacy Policy" : "Política de Privacidad"}
                        </Link>
                    </div>
                    <div className="flex items-center">
                        <span className="text-gray-400 text-base flex items-center">
                            {language === "gaia-en" ? "Made with " : "Hecho con "}
                            <Heart className="w-4 h-4 mx-1 text-red-500 animate-pulse" />
                            {language === "gaia-en" ? " in Peru" : " en Perú"}
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;