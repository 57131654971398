import { useState, useEffect } from "react";
import { FaLanguage, FaPen, FaUpload } from 'react-icons/fa';
import { Info, MemoryStick } from "lucide-react"

const Sota = ({ user, language }) => {
    const [selectedModel, setSelectedModel] = useState(null);
    const [srcLang, setSrcLang] = useState("");
    const [tgtLang, setTgtLang] = useState("");

    useEffect(() => {
        if (selectedModel) {
            setSrcLang(selectedModel.src_lang);
            setTgtLang(selectedModel.tgt_lang);
        }
    }, [selectedModel]);

    return (
        <div translate="no" className="max-w-4xl bg-white mx-auto p-8">
            <CreateModel language={language} user={user} />
        </div>
    );
}

export default Sota;

const CreateModel = ({ user, language }) => {
    const [srcLang, setSrcLang] = useState("");
    const [tgtLang, setTgtLang] = useState("");
    const [coreMemory, setCoreMemory] = useState("");
    const [customPrompt, setCustomPrompt] = useState("");
    const [jsonFile, setJsonFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/json") {
            setJsonFile(file);
            setError("");
        } else {
            setError(language === "gaia-en" ? "Please upload a valid JSON file" : "Por favor suba un archivo JSON válido");
            setJsonFile(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        setSuccess(false);

        if (!jsonFile || !srcLang || !tgtLang || !customPrompt) {
            setError(language === "gaia-en" ? "Please fill in all required fields" : "Por favor complete todos los campos requeridos");
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append("parallel_sentences", jsonFile);
        formData.append("src_lang", srcLang.toLocaleLowerCase());
        formData.append("tgt_lang", tgtLang.toLocaleLowerCase());
        formData.append("custom_prompt", customPrompt);
        formData.append("core_memory", coreMemory);
        formData.append("uid", user.uid);

        try {
            const response = await fetch("https://agentic.gaia-ml.com/api/create-model/", {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error(language === "gaia-en" ? "Failed to create model" : "Error al crear el modelo");
            }

            const data = await response.json();
            setSuccess(true);
            setSrcLang("");
            setTgtLang("");
            setCoreMemory("");
            setCustomPrompt("");
            setJsonFile(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const RequiredLabel = () => (
        <span className="text-red-500 ml-1">*</span>
    );

    return (
        <div className="max-w-4xl mt-20 mx-auto p-8 bg-white">
            <p className="pb-8">
                {language === "gaia-en" ? "Before starting please take a look at this" : "Antes de comenzar, por favor revise este"} <a href="https://maresone.s3.us-east-1.amazonaws.com/sample_dataset.json" target="_blank" className="text-blue-600 underline hover:text-blue-800 cursor-pointer">{language === "gaia-en" ? "sample dataset" : "conjunto de datos de ejemplo"}</a>. Download and examine it before creating your model.
            </p>
            <div className="mb-4 text-sm text-gray-600">
                {language === "gaia-en" ? "Fields marked with" : "Los campos marcados con"} <span className="text-red-500">*</span> {language === "gaia-en" ? "are required" : "son obligatorios"}
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-base font-medium text-indigo-700 mb-2 flex items-center">
                            <FaLanguage className="mr-2" />
                            {language === "gaia-en" ? "Source Language" : "Idioma de Origen"}
                            <RequiredLabel />
                        </label>
                        <input
                            type="text"
                            value={srcLang}
                            onChange={(e) => setSrcLang(e.target.value)}
                            className="w-full rounded-xl border border-indigo-200 p-3 outline-none"
                            placeholder={language === "gaia-en" ? "Source language name" : "Nombre del idioma de origen"}
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-base font-medium text-indigo-700 mb-2 flex items-center">
                            <FaLanguage className="mr-2" />
                            {language === "gaia-en" ? "Target Language" : "Idioma de Destino"}
                            <RequiredLabel />
                        </label>
                        <input
                            type="text"
                            value={tgtLang}
                            onChange={(e) => setTgtLang(e.target.value)}
                            className="w-full rounded-xl border border-indigo-200 p-3 outline-none"
                            placeholder={language === "gaia-en" ? "Target language name" : "Nombre del idioma de destino"}
                            required
                        />
                    </div>
                </div>

                <div>
                    <label className="block text-base font-medium text-indigo-700 mb-2 flex items-center">
                        <MemoryStick className="mr-2" />
                        {language === "gaia-en" ? "Core Memory" : "Memoria Principal"}
                        <span className="ml-2 text-sm text-gray-500">({language === "gaia-en" ? "Optional" : "Opcional"})</span>
                    </label>
                    <textarea
                        value={coreMemory}
                        onChange={(e) => setCoreMemory(e.target.value)}
                        rows={4}
                        className="w-full rounded-xl border border-indigo-200 p-4 outline-none"
                        placeholder={language === "gaia-en" ? "Enter basic language rules and samples here" : "Ingrese reglas básicas del idioma y ejemplos aquí"}
                    />
                </div>

                <div>
                    <label className="block text-base font-medium text-indigo-700 mb-2 flex items-center">
                        <Info className="mr-2" />
                        {language === "gaia-en" ? "Description" : "Descripción"}
                        <RequiredLabel />
                    </label>
                    <textarea
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                        rows={4}
                        className="w-full rounded-xl border border-indigo-200 p-4 outline-none"
                        placeholder={language === "gaia-en" ? "Enter a description for your model" : "Ingrese una descripción para su modelo"}
                        required
                    />
                </div>

                <div>
                    <label className="block text-base font-medium text-indigo-700 mb-2 flex items-center">
                        <FaUpload className="mr-2" />
                        {language === "gaia-en" ? "Parallel Sentences JSON" : "JSON de Oraciones Paralelas"}
                        <RequiredLabel />
                    </label>
                    <input
                        type="file"
                        accept="application/json"
                        onChange={handleFileChange}
                        className="w-full rounded-xl border border-indigo-200 p-3 outline-none file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-base file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                        required
                    />
                </div>

                <div className="flex justify-center">
                    <button
                        type="submit"
                        disabled={loading || !jsonFile}
                        className={`rounded-full bg-gradient-to-r from-indigo-600 to-blue-600 py-3 px-8 text-white font-semibold shadow-lg hover:from-indigo-500 hover:to-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2 transition ${(loading || !jsonFile) && "opacity-50 cursor-not-allowed"}`}
                    >
                        {loading ? language === "gaia-en" ? "Creating Model..." : "Creando Modelo..." : language === "gaia-en" ? "Create Model" : "Crear Modelo"}
                    </button>
                </div>
            </form>

            {error && (
                <div className="mt-6 p-4 bg-red-100 border border-red-300 rounded-xl text-red-700">
                    {error}
                </div>
            )}

            {success && (
                <div className="mt-6 p-4 bg-green-100 border border-green-300 rounded-xl text-green-700">
                    {language === "gaia-en" ? "Model created successfully!" : "¡Modelo creado exitosamente!"}
                </div>
            )}
        </div>
    );
}