import React, { createContext, useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { auth, googleProvider, db } from "./firebase";
import PrivacyPolicy from "./components/privacy";
import Home from "./components/home";
import Models from "./components/models";
import Docs from "./components/docs";
import Dashboard from "./components/dashboard";
import ModelDetail from "./components/modelDetail";
import Header from "./components/header";
import PublicModel from "./components/publicModel";

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

const LanguageModal = ({ isOpen, onSelectLanguage }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black z-50 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-12 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4">Select Your Language</h2>
        <div className="space-y-4">
          <button
            onClick={() => onSelectLanguage('gaia-en')}
            className="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center gap-2 justify-center"
          >
            <svg width="30" height="20" viewBox="0 0 7410 3900">
              <rect width="7410" height="3900" fill="#b22234" />
              <path d="M0,450H7410m0,600H0m0,600H7410m0,600H0m0,600H7410m0,600H0" stroke="#fff" strokeWidth="300" />
              <rect width="2964" height="2100" fill="#3c3b6e" />
            </svg>
            English
          </button>
          <button
            onClick={() => onSelectLanguage('gaia-es')}
            className="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center gap-2 justify-center"
          >
            <svg width="30" height="20" viewBox="0 0 900 600">
              <rect width="900" height="600" fill="#D91023" />
              <rect x="300" width="300" height="600" fill="#fff" />
            </svg>
            Español
          </button>
        </div>
      </div>
    </div>
  );
};

function App() {
  const [user] = useAuthState(auth);
  const [models, setModels] = useState([]);
  const [publicModels, setPublicModels] = useState([]);
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('preferredLanguage') || '';
  });
  const [showLanguageModal, setShowLanguageModal] = useState(false);

  useEffect(() => {
    if (!language) {
      setShowLanguageModal(true);
    }
  }, [language]);

  const handleLanguageSelect = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    localStorage.setItem('preferredLanguage', selectedLanguage);
    setShowLanguageModal(false);
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
    } catch (error) {
    }
  };

  const signInWithEmail = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
    }
  };

  const signOut = () => {
    auth.signOut();
  };

  const [sotaModels, setSotaModels] = useState([]);

  useEffect(() => {
    if (user) {
      const sotaRef = collection(db, "sota");
      const q = query(sotaRef, where("uid", "==", user.uid));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const sotaModelsList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSotaModels(sotaModelsList);
      }, (error) => {
      });

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <Router>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <LanguageModal
          isOpen={showLanguageModal}
          onSelectLanguage={handleLanguageSelect}
        />
        <Header
          user={user}
          signInWithGoogle={signInWithGoogle}
          signInWithEmail={signInWithEmail}
          signOut={signOut}
          language={language}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                user={user}
                signInWithGoogle={signInWithGoogle}
                signInWithEmail={signInWithEmail}
                language={language}
              />
            }
          />
          {user && (
            <>
              <Route
                path="/dashboard"
                element={
                  <Dashboard
                    user={user}
                    models={models}
                    sotaModels={sotaModels}
                    language={language}
                  />
                }
              />
              <Route
                path="/dashboard/:modelId"
                element={<ModelDetail user={user} language={language} />}
              />
            </>
          )}
          <Route path="/documentation" element={<Docs language={language} />} />
          <Route path="/privacy" element={<PrivacyPolicy language={language} />} />
          <Route path="/models" element={<Models publicModels={publicModels} language={language} />} />
          <Route path="/models/:modelId" element={<PublicModel user={user} language={language} />} />
        </Routes>
      </LanguageContext.Provider>
    </Router>
  );
}

export default App;