import React, { useEffect, useState } from 'react';
import Footer from "./footer";
import {
    ArrowRight, FileUp, Settings, Cpu, BarChart, Globe, X, Eye, EyeOff,
    User, Mail, Phone, Flag, Languages, Linkedin, Twitter
} from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import EnhancedFeaturesMetrics from "./features";
import AutoDemo from "./animation";
import TutorialPopup from "./popup"
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
} from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { auth, db } from "../firebase";

const Home = ({ user, language }) => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isRegister, setIsRegister] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        whatsapp: '',
        country: '',
        languagePairs: '',
        socialMedia: ''
    });

    const [showTutorial, setShowTutorial] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleTutorialClick = () => {
        setShowTutorial(true);
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;
            const userData = {
                name: formData.name,
                surname: formData.surname,
                email: formData.email,
                whatsapp: formData.whatsapp || null,
                country: formData.country,
                languagePairs: formData.languagePairs,
                socialMedia: formData.socialMedia || null,
                createdAt: new Date(),
            };
            await setDoc(doc(db, "users", user.uid), userData);
            setShowPopup(false);
            navigate("/dashboard");
        } catch (error) {
            alert(error.message);
        }
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, formData.email, formData.password);
            setShowPopup(false);
            navigate("/dashboard");
        } catch (error) {
            alert(error.message);
        }
    };

    const handleSubmit = isRegister ? handleRegister : handleSignIn;

    const handleStartNow = () => {
        if (user) {
            navigate("/dashboard");
        } else {
            setShowPopup(true);
        }
    };

    return (
        <div className="min-h-screen z-10 bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white overflow-x-hidden" translate="no">
            <main className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-12 py-12">
                <section className="relative grid sm:grid-cols-2 grid-cols-1 py-8 sm:py-16 mb-10 sm:mb-20">
                    <div className="flex items-center">
                        <div>
                            <h1 className="text-5xl font-bold mb-6 leading-tight">
                                {language === "gaia-en"
                                    ? "Build Your Own Translation Agent"
                                    : "Construye Tu Propio Agente de Traducción"
                                }
                            </h1>
                            <p className="text-xl text-gray-300 mb-8 max-w-2xl">
                                {language === "gaia-en"
                                    ? "No coding required. Build, deploy, and commercialize your translation agents with just a few clicks."
                                    : "No requiere programación. Construye, implementa y comercializa tus agentes de traducción con solo unos clics."
                                }
                            </p>
                            <div className="flex flex-col sm:flex-row gap-4 sm:gap-2">
                                <button
                                    onClick={handleTutorialClick}
                                    className="relative inline-flex items-center justify-center px-8 py-3 font-bold text-white bg-gradient-to-r from-blue-600 to-indigo-400 rounded-full shadow-lg transform transition-transform hover:scale-105 hover:from-indigo-500 hover:to-blue-500"
                                >
                                    <span>Tutorial</span> <ArrowRight className="ml-2" />
                                </button>
                                <Link to="/models"
                                    className="relative sm:ml-2 mt-4 sm:mt-0 border-2 border-blue-600 inline-flex items-center justify-center px-8 py-3 font-bold bg-transparent rounded-full transform transition-transform hover:scale-105"
                                >
                                    <span className="bg-gradient-to-r from-blue-600 to-indigo-400 bg-clip-text text-transparent hover:from-indigo-500 hover:to-blue-500">{language === "gaia-en" ? "See agents" : "Ver agentes"}
                                    </span>
                                    <ArrowRight className="ml-2 text-indigo-400 hover:text-blue-500" />
                                </Link>
                            </div>
                            <div className='mt-8 flex justify-center sm:justify-start'>
                                <a
                                    href="https://www.producthunt.com/posts/gaia-3?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-gaia-3"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=492556&theme=light"
                                        alt="Gaia - No-code tool to build and deploy machine translation models | Product Hunt"
                                        style={{
                                            width: '250px',
                                            height: '54px'
                                        }}
                                        width={250}
                                        height={54}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center sm:mx-12 mt-10 relative">
                        <div className="absolute inset-0 bg-gradient-to-br from-blue-600 to-indigo-600 opacity-20 rounded-lg"></div>
                        <AutoDemo language={language} />
                    </div>
                    <div className=" right-0 w-1/3 h-1/3 max-w-xs max-h-xs bg-gradient-to-tr from-blue-600 to-indigo-600 opacity-30 rounded-full transform -translate-y-1/2 translate-x-1/4 blur-2xl"></div>
                </section>

                <EnhancedFeaturesMetrics language={language} />
                <AnimatedHowItWorks language={language} />
                <section className="text-center mb-20">
                    <h2 className="text-3xl font-semibold mb-6">
                        {language === "gaia-en"
                            ? "Ready to build your translator?"
                            : "¿Listo para construir tu traductor?"
                        }
                    </h2>
                    <button
                        onClick={handleStartNow}
                        className="relative inline-flex items-center justify-center px-8 py-3 font-bold text-white bg-gradient-to-r from-indigo-600 to-blue-600 rounded-full shadow-lg transform transition-transform hover:scale-105 hover:from-indigo-500 hover:to-blue-500"
                    >
                        <span>
                            {language === "gaia-en" ? "Start Now" : "Empezar Ahora"}
                        </span> <ArrowRight className="ml-2" />
                    </button>
                </section>
            </main>
            <Footer language={language} />
            <TutorialPopup
                isOpen={showTutorial}
                onClose={() => setShowTutorial(false)}
            />
        </div>
    );
};

export default Home;

const AnimatedHowItWorks = ({language}) => {
    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {
            icon: FileUp,
            text: language === "gaia-en"
                ? "Upload your parallel data in a JSON file"
                : "Sube tus datos paralelos en un archivo JSON"
        },
        {
            icon: Settings,
            text: language === "gaia-en"
                ? "Set the names of source and target languages"
                : "Establece los nombres de los idiomas de origen y destino"
        },
        {
            icon: Cpu,
            text: language === "gaia-en"
                ? "Add a description"
                : "Añade una descripción"
        },
        {
            icon: BarChart,
            text: language === "gaia-en"
                ? "Click on Create"
                : "Haz clic en Crear"
        },
        {
            icon: Globe,
            text: language === "gaia-en"
                ? "View and use your translation agent from the dashboard"
                : "Visualiza y usa tu agente de traducción desde el panel"
        }
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveStep((prevStep) => (prevStep + 1) % steps.length);
        }, 3000);
        return () => clearInterval(timer);
    }, []);

    return (
        <section className="relative bg-gray-800 rounded-3xl shadow-lg px-8 pt-8 pb-12 mb-20">
            <div className="absolute inset-0 bg-gradient-to-tr from-indigo-900 via-transparent to-gray-800 opacity-50 rounded-3xl pointer-events-none"></div>
            <h2 className="text-3xl font-semibold text-center mb-12">
                {language === "gaia-en" ? "How It Works" : "¿Cómo Funciona?"}
            </h2>
            <div className="relative">
                <div
                    className="absolute left-1/2 transform -translate-x-1/2 w-1 bg-indigo-500 transition-all duration-1000 ease-in-out"
                    style={{ height: `${(activeStep + 1) * 20}%` }}
                />
                <ol className="relative z-10">
                    {steps.map((step, index) => {
                        const isActive = index === activeStep;
                        return (
                            <li key={index} className={`flex items-center mb-12 ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
                                <div
                                    className={`flex-1 ${index % 2 === 0 ? 'text-right pr-8' : 'pl-8'} transition-all duration-500`}
                                    style={{
                                        opacity: isActive ? 1 : 0.5,
                                        transform: isActive ? 'translateY(0)' : 'translateY(20px)'
                                    }}
                                >
                                    <p className={`text-xl ${isActive ? 'font-semibold text-white' : 'text-gray-500'}`}>{step.text}</p>
                                </div>
                                <div
                                    className={`flex-shrink-0 w-14 h-14 rounded-full flex items-center justify-center z-10 transition-all duration-500
                    ${isActive ? 'bg-indigo-600 shadow-lg' : 'bg-indigo-300'}`}
                                    style={{
                                        transform: isActive ? 'scale(1.1)' : 'scale(1)'
                                    }}
                                >
                                    <step.icon size={28} className="text-white" />
                                </div>
                                <div className={`flex-1 ${index % 2 === 0 ? 'pl-8' : 'pr-8'}`}></div>
                            </li>
                        );
                    })}
                </ol>
            </div>
        </section>
    );
};
